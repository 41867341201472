import Dispatch from '@/libs/dipatch'

/** 管理系统管理用户表相关 */
export const userDispatch = new Dispatch({
  getUserList: '/sys/user/list',
  getAppUserList: '/sys/user/appUser',
  deleteUserById: '/sys/user/delete',
  checkUsername: '/sys/user/checkUsername',
  checkMobile: '/sys/user/checkMobile',
  checkEmail: '/sys/user/checkEmail',
  updateUserById: '/sys/user/update',
  addUser: '/register',
  updateUserBatchById: '/sys/user/batchUpdate',
  getUserRole: '/sys/user/getUserRole',
  updateUserRole: '/sys/user/updateUserRole',
  checkPassword: '/sys/user/checkPassword',
  export: '/sys/user/export',
  exportTemplate: '/sys/user/exportTemplate',
  importData: '/sys/user/importData',
  updatePassword: '/updatePassword'
})

/** 管理机构管理表相关 */
export const groupDispatch = new Dispatch({
  getGroupList: '/sys/group/list',
  queryGroupList: '/sys/group/queryGroupTreeList',
  getGroupDetail: '/sys/group/info',
  deleteGroupById: '/sys/group/delete',
  updateGroupById: '/sys/group/update',
  addGroup: '/sys/group/save',
  getGroupUser: '/sys/group/getGroupUser',
  updateGroupUser: '/sys/group/updateGroupUser',
  getGroupRole: '/sys/group/getGroupRole',
  updateGroupRole: '/sys/group/updateGroupRole'
})

/** 管理菜单管理表相关 */
export const menuDispatch = new Dispatch({
  deleteMenu: '/sys/menu/delete',
  check: '/sys/menu/check',
  updateMenuById: '/sys/menu/update',
  addMenu: '/sys/menu/save',
  getTreeList: '/sys/menu/tree',
  getCatalogList: '/sys/menu/getCatalog',
  transfer: '/sys/menu/transfer'
})

/** 管理角色管理表相关 */
export const roleDispatch = new Dispatch({
  getRoleList: '/sys/role/list',
  deleteRoleById: '/sys/role/delete',
  updateRoleById: '/sys/role/update',
  addRole: '/sys/role/save'
})

/** 管理角色管理表相关 */
export const fileDispatch = new Dispatch({
  uploadImg: '/uploadImg'
})

/** 管理调度任务信息相关 */
export const jobInfoDispatch = new Dispatch({
  getJobInfoList: '/sys/jobInfo/list',
  deleteJobInfoById: '/sys/jobInfo/delete',
  check: '/sys/jobInfo/check',
  updateJobInfoById: '/sys/jobInfo/update',
  addJobInfo: '/sys/jobInfo/save',
  updateJobInfoBatchById: '/sys/jobInfo/batchUpdate',
  export: '/sys/jobInfo/export',
  exportTemplate: '/sys/jobInfo/exportTemplate',
  importData: '/sys/jobInfo/importData',
  refreshJob: '/sys/jobInfo/refreshJob',
  refreshAll: '/sys/jobInfo/refreshAll',
  getJobLogList: '/sys/jobInfo/log',
  deleteJobLog: '/sys/jobInfo/deleteLog'
})

/** 管理地区表相关 */
export const areaDispatch = new Dispatch({
  getAreaList: '/sys/area/list',
  getAreaDetail: '/sys/area/info',
  deleteAreaById: '/sys/area/delete',
  check: '/sys/area/check',
  updateAreaById: '/sys/area/update',
  addArea: '/sys/area/save',
  queryAreaList: '/sys/area/queryAreaTreeList'
})

/** 管理商家相关 */
export const shopDispatch = new Dispatch({
  getShopList: '/shop/shop/list',
  deleteShopById: '/shop/shop/delete',
  check: '/shop/shop/check',
  updateShopById: '/shop/shop/update',
  addShop: '/shop/shop/save',
  updateShopBatchById: '/shop/shop/batchUpdate',
  export: '/shop/shop/export',
  exportTemplate: '/shop/shop/exportTemplate',
  importData: '/shop/shop/importData',
  refund: '/shop/shop/refund/order'
})

/** 管理商家相关 */
export const shopRoomDispatch = new Dispatch({
  getShopRoomList: '/room/shopRoom/list',
  deleteShopRoomById: '/room/shopRoom/delete',
  check: '/room/shopRoom/check',
  updateShopRoomById: '/room/shopRoom/update',
  addShopRoom: '/room/shopRoom/save',
  updateShopRoomBatchById: '/room/shopRoom/batchUpdate',
  export: '/room/shopRoom/export',
  exportTemplate: '/room/shopRoom/exportTemplate',
  importData: '/room/shopRoom/importData'
})

/** 管理优惠券相关 */
export const couponDispatch = new Dispatch({
  getCouponList: '/coupon/coupon/list',
  deleteCouponById: '/coupon/coupon/delete',
  check: '/coupon/coupon/check',
  updateCouponById: '/coupon/coupon/update',
  addCoupon: '/coupon/coupon/save',
  distributeCoupon: '/coupon/coupon/distribute',
  updateCouponBatchById: '/coupon/coupon/batchUpdate',
  export: '/coupon/coupon/export',
  exportTemplate: '/coupon/coupon/exportTemplate',
  importData: '/coupon/coupon/importData',
  getCouponCategoryList: '/coupon/coupon/getCouponCategoryList'
})

/** 管理充值活动相关 */
export const activityDispatch = new Dispatch({
  getActivityList: '/activity/activity/list',
  deleteActivityById: '/activity/activity/delete',
  check: '/activity/activity/check',
  updateActivityById: '/activity/activity/update',
  addActivity: '/activity/activity/save',
  updateActivityBatchById: '/activity/activity/batchUpdate',
  export: '/activity/activity/export',
  exportTemplate: '/activity/activity/exportTemplate',
  importData: '/activity/activity/importData'
})
