export default {
  logo: 'k-platform',
  title: 'User Login',
  home: 'Home',
  login: 'Login',
  selectLanguage: 'The switchover succeeded',
  themeColorChange: 'Change of theme color',
  usernameTip: 'Please enter the user name',
  passwordTip: 'Please enter the password',
  codeTip: 'Please enter the code number',
  usernameRule: 'The account cannot be empty',
  passwordRule: 'The password cannot be empty',
  passwordLengthRule: 'The password must contain at least six characters',
  codeRule: 'The code number cannot be empty',
  codeLengthRule: 'The ode number must contain at least four characters',
  components: 'Components',
  confirm: 'confirm',
  cancel: 'cancel',
  closeOther: 'Close Others',
  closeAll: 'Close All',
  close: 'close',
  next: 'next',
  prev: 'previous',
  guideTitle: 'guidance',
  guideDesc: 'Turn on the boot function',
  hamburgerTitle: 'Hamburger button',
  hamburgerDesc: 'Open and close the left menu',
  breadcrumbTitle: 'Bread crumbs',
  breadcrumbDesc: 'Indicates the current page position',
  searchTitle: 'search',
  searchDesc: 'Page link search',
  fullTitle: 'full screen',
  fullDesc: 'Page display switching',
  themeTitle: 'theme',
  themeDesc: 'Change project theme',
  langTitle: 'globalization',
  langDesc: 'Language switch',
  tagTitle: 'Label',
  tagDesc: 'Opened page tab',
  sidebarTitle: 'menu',
  sidebarDesc: 'Project function menu',
  count_to_page: 'Count-to',
  tables_page: 'Table',
  split_pane_page: 'Split-pane',
  markdown_page: 'Markdown-editor',
  editor_page: 'Rich-Text-Editor',
  icons_page: 'Custom-icon',
  img_cropper_page: 'Image-editor',
  update: 'Update',
  doc: 'Document',
  join_page: 'QQ Group',
  update_table_page: 'Update .CSV',
  update_paste_page: 'Paste Table Data',
  multilevel: 'multilevel',
  directive_page: 'Directive',
  level_1: 'Level-1',
  level_2: 'Level-2',
  level_2_1: 'Level-2-1',
  level_2_3: 'Level-2-3',
  level_2_2: 'Level-2-2',
  level_2_2_1: 'Level-2-2-1',
  level_2_2_2: 'Level-2-2-2',
  excel: 'Excel',
  'upload-excel': 'Upload Excel',
  'export-excel': 'Export Excel',
  tools_methods_page: 'Tools Methods',
  drag_list_page: 'Drag-list',
  i18n_page: 'Internationalization',
  modalTitle: 'Modal Title',
  content: 'This is the modal box content.',
  buttonText: 'Show Modal',
  'i18n-tip':
    'Note: Only this page is multi-language, other pages do not add language content to the multi-language package.',
  error_store_page: 'Error Collection',
  error_logger_page: 'Error Logger',
  query: 'Query',
  params: 'Params',
  cropper_page: 'Cropper',
  message_page: 'Message Center',
  tree_table_page: 'Tree Table',
  org_tree_page: 'Org Tree',
  drag_drawer_page: 'Draggable Drawer',
  tree_select_page: 'Tree Selector',
  tags_management: 'Tags Management',
  user: 'User',
  user_management: 'User Management',
  group_management: 'Group Management',
  menu_management: 'Menu Management',
  menu_options: 'Menu Options',
  resources: 'Resources',
  role_management: 'Role Management',
  role_list: 'Role List',
  jobInfo_management: 'JobInfo Management',
  area_management: 'Area Management',
  shop: 'Shop',
  shop_management: 'Shop Management',
  shopRoom: 'ShopRoom',
  shopRoom_management: 'ShopRoom Management',
  coupon: 'Coupon',
  coupon_management: 'Coupon Management',
  activity: 'Activity',
  activity_management: 'Activity Management'
}
